import payload_plugin_sSU4PVnuUX from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.7.0_magicast@0.3.5_rollup@4.24.0_typescript@5.5.4_vue@3.5.12_typescript@5.5.4__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_WeBx7NLRxF from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.9.0_eslint@9.14.0_jiti@2.4.0__ioredis@5.4.1__ivtegmacjinplrfnzi2kmnmz24/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_ZrV66wOhLU from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.9.0_eslint@9.14.0_jiti@2.4.0__ioredis@5.4.1__ivtegmacjinplrfnzi2kmnmz24/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_vRj434y9s8 from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.9.0_eslint@9.14.0_jiti@2.4.0__ioredis@5.4.1__ivtegmacjinplrfnzi2kmnmz24/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_6VTPmbHHh5 from "/vercel/path0/node_modules/.pnpm/nuxt-site-config@2.2.21_magicast@0.3.5_rollup@4.24.0_vite@5.4.8_@types+node@22.9.0_sass@1.80._pafnoicfvwyz2utfxcbvrw4jmi/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_FSkWyBid6e from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.9.0_eslint@9.14.0_jiti@2.4.0__ioredis@5.4.1__ivtegmacjinplrfnzi2kmnmz24/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_KBAnMNU7GS from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.9.0_eslint@9.14.0_jiti@2.4.0__ioredis@5.4.1__ivtegmacjinplrfnzi2kmnmz24/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_pSXfNNzZFM from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.9.0_eslint@9.14.0_jiti@2.4.0__ioredis@5.4.1__ivtegmacjinplrfnzi2kmnmz24/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_0q0sZGqkWE from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.9.0_eslint@9.14.0_jiti@2.4.0__ioredis@5.4.1__ivtegmacjinplrfnzi2kmnmz24/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_zbacBAXhj0 from "/vercel/path0/.nuxt/modules/@nuxt-scripts/plugin.mjs";
import plugin_vue3_GpTyenzC2s from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.7.0_magicast@0.3.5_rollup@4.24.0_typescript@5.5.4_vue@3.5.12_typescript@5.5.4__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_V6T7iNJ0F7 from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.9.0_eslint@9.14.0_jiti@2.4.0__ioredis@5.4.1__ivtegmacjinplrfnzi2kmnmz24/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_5THmCYSCuw from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.24.0_vue@3.5.12_typescript@5.5.4__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_SsXXOFiF2K from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.24.0_vue@3.5.12_typescript@5.5.4__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import siteConfig_iyHNDOmiHg from "/vercel/path0/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_magicast@0.3.5_rollup@4.24.0_vite@5.4.8_@types+node@22.9.0_sass@1._pggf3hjb5rtxzg63nwptx4od3u/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_FKZFz3UYbo from "/vercel/path0/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_magicast@0.3.5_rollup@4.24.0_vite@5.4.8_@types+node@22.9.0_sass@1._pggf3hjb5rtxzg63nwptx4od3u/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import plugin_wy0B721ODc from "/vercel/path0/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_d7Lswdh5Ld from "/vercel/path0/node_modules/.pnpm/nuxt-quasar-ui@2.1.6_@quasar+extras@1.16.12_magicast@0.3.5_quasar@2.17.2_rollup@4.24.0_webpack-sources@3.2.3/node_modules/nuxt-quasar-ui/dist/runtime/plugin.js";
import rest_api_zNWx1STPxo from "/vercel/path0/plugins/rest-api.js";
import error_handler_kEP5FliEXj from "/vercel/path0/plugins/error-handler.ts";
import image_error_Tix9NvX1QD from "/vercel/path0/plugins/image-error.ts";
import sentry_3AyO8nEfhE from "/vercel/path0/plugins/sentry.ts";
import init_MfZeTNsfcv from "/vercel/path0/node_modules/.pnpm/nuxt-schema-org@3.4.1_magicast@0.3.5_rollup@4.24.0_vite@5.4.8_@types+node@22.9.0_sass@1.80.6__ohzcpmrra6db35lhfjesxkyiki/node_modules/nuxt-schema-org/dist/runtime/nuxt/plugin/i18n/init.js";
export default [
  payload_plugin_sSU4PVnuUX,
  revive_payload_client_WeBx7NLRxF,
  unhead_ZrV66wOhLU,
  router_vRj434y9s8,
  _0_siteConfig_6VTPmbHHh5,
  payload_client_FSkWyBid6e,
  navigation_repaint_client_KBAnMNU7GS,
  check_outdated_build_client_pSXfNNzZFM,
  chunk_reload_client_0q0sZGqkWE,
  plugin_zbacBAXhj0,
  plugin_vue3_GpTyenzC2s,
  components_plugin_KR1HBZs4kY,
  prefetch_client_V6T7iNJ0F7,
  switch_locale_path_ssr_5THmCYSCuw,
  i18n_SsXXOFiF2K,
  siteConfig_iyHNDOmiHg,
  inferSeoMetaPlugin_FKZFz3UYbo,
  plugin_wy0B721ODc,
  plugin_d7Lswdh5Ld,
  rest_api_zNWx1STPxo,
  error_handler_kEP5FliEXj,
  image_error_Tix9NvX1QD,
  sentry_3AyO8nEfhE,
  init_MfZeTNsfcv
]