import { default as _91id_931mf9pMu3VrMeta } from "/vercel/path0/pages/admin/[slug]/[id].vue?macro=true";
import { default as index31JoRWqumOMeta } from "/vercel/path0/pages/admin/reviews/index.vue?macro=true";
import { default as indexbLejLX1QXgMeta } from "/vercel/path0/pages/admin/table/index.vue?macro=true";
import { default as indexZidYEKGUi3Meta } from "/vercel/path0/pages/comparison/index.vue?macro=true";
import { default as indexs8g6T0dsz8Meta } from "/vercel/path0/pages/faq/index.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as indexSsrwCXeuTQMeta } from "/vercel/path0/pages/profile/index.vue?macro=true";
import { default as indexYDlMnzOPg9Meta } from "/vercel/path0/pages/search/[id]/index.vue?macro=true";
import { default as indexDqV3Lxa5AvMeta } from "/vercel/path0/pages/search/index.vue?macro=true";
import { default as emailEugXcDDUXgMeta } from "/vercel/path0/pages/third-party-auth/email.vue?macro=true";
import { default as googleUNaLIGb4GoMeta } from "/vercel/path0/pages/third-party-auth/google.vue?macro=true";
import { default as component_45stubSxYABY4YXfMeta } from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.9.0_eslint@9.14.0_jiti@2.4.0__ioredis@5.4.1__ivtegmacjinplrfnzi2kmnmz24/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubSxYABY4YXf } from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.9.0_eslint@9.14.0_jiti@2.4.0__ioredis@5.4.1__ivtegmacjinplrfnzi2kmnmz24/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "admin-slug-id___en",
    path: "/admin/:slug()/:id()",
    component: () => import("/vercel/path0/pages/admin/[slug]/[id].vue")
  },
  {
    name: "admin-slug-id___de",
    path: "/de/admin/:slug()/:id()",
    component: () => import("/vercel/path0/pages/admin/[slug]/[id].vue")
  },
  {
    name: "admin-reviews___en",
    path: "/admin/reviews",
    meta: index31JoRWqumOMeta || {},
    component: () => import("/vercel/path0/pages/admin/reviews/index.vue")
  },
  {
    name: "admin-reviews___de",
    path: "/de/admin/reviews",
    meta: index31JoRWqumOMeta || {},
    component: () => import("/vercel/path0/pages/admin/reviews/index.vue")
  },
  {
    name: "admin-table___en",
    path: "/admin/table",
    meta: indexbLejLX1QXgMeta || {},
    component: () => import("/vercel/path0/pages/admin/table/index.vue")
  },
  {
    name: "admin-table___de",
    path: "/de/admin/table",
    meta: indexbLejLX1QXgMeta || {},
    component: () => import("/vercel/path0/pages/admin/table/index.vue")
  },
  {
    name: "comparison___en",
    path: "/comparison",
    meta: indexZidYEKGUi3Meta || {},
    component: () => import("/vercel/path0/pages/comparison/index.vue")
  },
  {
    name: "comparison___de",
    path: "/de/comparison",
    meta: indexZidYEKGUi3Meta || {},
    component: () => import("/vercel/path0/pages/comparison/index.vue")
  },
  {
    name: "faq___en",
    path: "/faq",
    meta: indexs8g6T0dsz8Meta || {},
    component: () => import("/vercel/path0/pages/faq/index.vue")
  },
  {
    name: "faq___de",
    path: "/de/faq",
    meta: indexs8g6T0dsz8Meta || {},
    component: () => import("/vercel/path0/pages/faq/index.vue")
  },
  {
    name: "index___en",
    path: "/",
    meta: indexe9Brt5DfdhMeta || {},
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "index___de",
    path: "/de",
    meta: indexe9Brt5DfdhMeta || {},
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "profile___en",
    path: "/profile",
    meta: indexSsrwCXeuTQMeta || {},
    component: () => import("/vercel/path0/pages/profile/index.vue")
  },
  {
    name: "profile___de",
    path: "/de/profile",
    meta: indexSsrwCXeuTQMeta || {},
    component: () => import("/vercel/path0/pages/profile/index.vue")
  },
  {
    name: "search-id___en",
    path: "/search/:id()",
    meta: indexYDlMnzOPg9Meta || {},
    component: () => import("/vercel/path0/pages/search/[id]/index.vue")
  },
  {
    name: "search-id___de",
    path: "/de/search/:id()",
    meta: indexYDlMnzOPg9Meta || {},
    component: () => import("/vercel/path0/pages/search/[id]/index.vue")
  },
  {
    name: "search___en",
    path: "/search",
    meta: indexDqV3Lxa5AvMeta || {},
    component: () => import("/vercel/path0/pages/search/index.vue")
  },
  {
    name: "search___de",
    path: "/de/search",
    meta: indexDqV3Lxa5AvMeta || {},
    component: () => import("/vercel/path0/pages/search/index.vue")
  },
  {
    name: "third-party-auth-email___en",
    path: "/third-party-auth/email",
    component: () => import("/vercel/path0/pages/third-party-auth/email.vue")
  },
  {
    name: "third-party-auth-email___de",
    path: "/de/third-party-auth/email",
    component: () => import("/vercel/path0/pages/third-party-auth/email.vue")
  },
  {
    name: "third-party-auth-google___en",
    path: "/third-party-auth/google",
    component: () => import("/vercel/path0/pages/third-party-auth/google.vue")
  },
  {
    name: "third-party-auth-google___de",
    path: "/de/third-party-auth/google",
    component: () => import("/vercel/path0/pages/third-party-auth/google.vue")
  },
  {
    name: component_45stubSxYABY4YXfMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubSxYABY4YXf
  },
  {
    name: component_45stubSxYABY4YXfMeta?.name,
    path: "/index-sitemap.xml",
    component: component_45stubSxYABY4YXf
  },
  {
    name: component_45stubSxYABY4YXfMeta?.name,
    path: "/en-US-sitemap.xml",
    component: component_45stubSxYABY4YXf
  },
  {
    name: component_45stubSxYABY4YXfMeta?.name,
    path: "/de-DE-sitemap.xml",
    component: component_45stubSxYABY4YXf
  }
]